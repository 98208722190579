import React from "react";
import { Outlet } from "react-router-dom";
import { useAuthContextHook } from "../../context/AuthContext";
import Error from '../NotFound/NotFound'
const LayoutMain = () => {
  // let { IsLogedIn } = useAuthContextHook();

  return (
  // <div>{ IsLogedIn ? <Outlet />: <Error/>}</div>
  <div> <Outlet /></div>
  
);
};

export default LayoutMain;
