import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URLs } from "../utils/ApiUrls";
import { browserName } from 'react-device-detect';

export const AuthContextHook = createContext();
export const useAuthContextHook = () => {
  return useContext(AuthContextHook);
};

export default function AuthContext({ children }) {
  const [IsLogedIn, setIsLogedIn] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [user, setuser] = useState([]);
  const[alowedRoleData, setAllowedRoleData] = useState([]);
  const[forgotLoader, setForgotLoader]=useState(false);
  const [loginCountry, setLoginCountry] = useState('')
  const [ipAdd, setIpAdd] = useState('')
  
  const [showRespModal, setShowRespModal] = useState(false);
  const [modalDataResp, setModalDataResp] = useState(null);
  const [navigateLink, setNavigateLink] = useState('');
  const handleShowModalResponse = (data) => {
      setModalDataResp(data);
      setShowRespModal(true);
  };
  const handleCloseModalResponse = () => {
      setShowRespModal(false)
  };

  const navigate = useNavigate();
  const loadinghandle = (data) => {
    setLoading(data);
  };
  const loadingOtpHandle = (data) => {
    setLoadingOtp(data);
  };

  const loginApi = (email, password) => {
    setLoading(true);
    axios.post(API_URLs.logIn, {
      email: email,
      password: password,
    },{
      headers: {
          'device-address': loginCountry,
          'device-type': browserName,
          'ip-address': ipAdd
      }
    })
    .then((res) => {
      localStorage.setItem("otpToken", res?.data?.token);
      setLoading(false);
      setIsLogedIn(true);
      handleShowModalResponse("We have sent a verification code to your email. Verification code is valid for 2 minutes only. Please check your email.");
      setNavigateLink('/login/otp'); 
    })
    .catch((error) => {
      setLoading(false);
      handleShowModalResponse(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured');
    });
  };

  const loginOtpApi = (otp) => {
    axios
      .post(API_URLs.loginVerify, {
        otp: otp,
        token: localStorage.getItem("otpToken"),
      })
      .then((res) => {
        navigate('/dashboard'); 
        setuser(res.data.data.user);
        setAllowedRoleData(res.data.data.user.roles[0]);
        localStorage.setItem("userToken", res.data.data.tokens.access.token);
        localStorage.setItem("userTokenOut", res.data.data.tokens.refresh.token);
        setLoadingOtp(false);
      })
      .catch((error) => {
        setNavigateLink('/login'); 
        setLoadingOtp(false);
        handleShowModalResponse(error?.response?.data?.message ? error?.response?.data?.message : "Some Problem Occured");
      });
  };

  const forgotApi = async (email) => {
    setForgotLoader(true);
    try {
      let res = await axios.post(API_URLs.forgetPassword, {
        email: email,
      });
      handleShowModalResponse(res.data.msg ? res.data.msg : "Link Send, Please Check Your Email");
      navigate("/");
      setForgotLoader(false)
    } catch (error) {
      handleShowModalResponse(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured');
      setForgotLoader(false)
    }
  };

  const handleResetPassword = async (password, token) => {
    try {
      let res = await axios.post(API_URLs.reset + "?token=" + token, {
        password: password,
      });
      handleShowModalResponse(res.data.msg ? res.data.msg : "Password Changed Successfully!!");
      navigate("/");
    } catch (error) {
      handleShowModalResponse(error?.response?.data?.message ? error.response.data.message : "Some Error Occured");
    }
  };

  const getProfileInfo = ()=> {
    axios.get(API_URLs.getProfile, {
      headers: {
        "accept": 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
      }
    })
      .then((res) => {
        setIsLogedIn(true)
        setuser(res.data.profle);
        setAllowedRoleData(res.data.profle.roles[0]);
      })
      .catch((error) => {
        setIsLogedIn(false)
      })
  } 

  const getYourCountry = () => {
    fetch('https://api.ipify.org?format=json')  
      .then((response) => response.json())
      .then((data) => {
        const userIP = data.ip;
        setIpAdd(userIP);
        fetch(`https://ipapi.co/${userIP}/country/`)
            .then((response) => response.text())
            .then((country) => {
                setLoginCountry(country)
            })
            .catch((error) => console.error('Error fetching country:', error));
        })
        .catch((error) => console.error('Error fetching IP:', error));
}

useEffect(() => {
    getYourCountry()
    getProfileInfo();
  },[]);

  

  const data = {
    IsLogedIn,
    setIsLogedIn,
    loginApi,
    user,
    loading,
    loadinghandle,
    loginOtpApi,
    loadingOtp,
    loadingOtpHandle,
    forgotApi,
    handleResetPassword,
    alowedRoleData,
    handleCloseModalResponse,
    navigateLink,
    showRespModal,
    forgotLoader,
    modalDataResp,
  };

  return (
    <AuthContextHook.Provider value={{ ...data }}>
      {children}
    </AuthContextHook.Provider>
  );
}
