// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  font-weight: bold;
}
.css-wx7wi4 {
  margin-right: 0px !important;
}

.imageQvaniLogo{
  width: 143px;
  height: 40px;
  margin-left: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/component/Sidebar/SidebarMenu.modules.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".active {\n  font-weight: bold;\n}\n.css-wx7wi4 {\n  margin-right: 0px !important;\n}\n\n.imageQvaniLogo{\n  width: 143px;\n  height: 40px;\n  margin-left: 7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
