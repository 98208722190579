// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,900;1,6..12,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_404 {
  padding: 100px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100vw;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
`, "",{"version":3,"sources":["webpack://./src/component/NotFound/NotFound.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+FAA+F;EAC/F,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,900;1,6..12,900&display=swap');\n\n.page_404 {\n  padding: 100px 0;\n  background: #fff;\n  font-family: \"Arvo\", serif;\n}\n\n.page_404 img {\n  width: 100vw;\n}\n\n.four_zero_four_bg {\n  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);\n  height: 400px;\n  background-position: center;\n}\n\n.four_zero_four_bg h1 {\n  font-size: 80px;\n}\n\n.four_zero_four_bg h3 {\n  font-size: 80px;\n}\n\n.link_404 {\n  color: #fff !important;\n  padding: 10px 20px;\n  background: #39ac31;\n  margin: 20px 0;\n  display: inline-block;\n}\n.contant_box_404 {\n  margin-top: -50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
