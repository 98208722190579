import React from "react";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom'
import { FaLongArrowAltLeft } from "react-icons/fa";
import { API_URLs } from "../../utils/ApiUrls";
import CustomModal from "../../component/Response/CustomModal";

import { useState, useEffect } from "react";
import styled from 'styled-components';
import SubmitLoader from '../../component/Loader/SubmitLoader';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useAuthContextHook } from "../../context/AuthContext";

export const Button = styled.button`
width: 7rem;
margin-top: 9px;
border: none;
background: #1476FF;
color: white;
border-radius: 20px;
padding: 6px 24px;
:disabled{
    opacity: 0.8;
}
`
const Button1 = styled.button`
margin-top: 9px;
border: none;
background: #1476FF;
color: white;
border-radius: 20px;
padding: 6px 24px;
:disabled{
    opacity: 0.8;
}
`
const Button2 = styled.button`
margin-top: 9px;
border: 1px solid red;
background: white;
color: black;
border-radius: 20px;
padding: 6px 24px;
:disabled{
    opacity: 0.8;
}
`
const SingleAdminRoleManagement = () => {

    const {alowedRoleData} = useAuthContextHook();

    const [showRespModal, setShowRespModal] = useState(false);
    const [modalDataResp, setModalDataResp] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModalResponse = (data) => {
        setModalDataResp(data);
        setShowRespModal(true);
    };
    const handleCloseModalResponse = () => {
        setShowRespModal(false)
    };


    
    
    const [roleLoader, setRoleLoader] = useState(false);
    const [giveLoader, setGiveLoader] = useState(false);
    const [resetLoader, setResetLoader] = useState(false);

    
    
    const params = useParams();
    const navigate = useNavigate();
    const [getPerAllData, setGetPerAllData] = useState([])
    const [givePermTo, setGivePermTo]= useState([]);

    
    
    const formik = useFormik({
        initialValues: { role: '' },
        validationSchema: Yup.object().shape({
            role: Yup.string(),
        }),
        onSubmit: (values) => {
            
            
            setRoleLoader(true)
            axios.put(API_URLs.getSingleRoleManagement(params.id), {
                name: values.role,
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            })
            .then((res) => {
                setRoleLoader(false)
                handleShowModalResponse('Update Successfully!!');
            })
            .catch((error) => {
                setRoleLoader(false);
                handleShowModalResponse(error?.response?.data?.message ? error.response?.data?.message : "Some Error occured");
            })
        }
    })

    
    
    const getSingleRoleManagement = () => {
        axios.get(API_URLs.getSingleRoleManagement(params.id), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                formik.setValues({
                    ...formik.values,
                    role: res?.data?.data?.name,
                })
            })
            .catch((error) => {
            })
    }

    
    
    const[isgetPerm, setIsGetPerm] = useState(false);
    const getSingleRolePermission = () => {
        axios.get(API_URLs.getSingleRolePermission(params.id), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                setGetPerAllData(res?.data?.data?.allPermissions)
                let arr=[];
                Object.entries(res?.data?.data?.rolePermissions?.permissions).map((e)=>{
                    e[1]?.map((data)=>{
                        arr.push(data._id);
                    })
                    
                    
                    
                    
                })
                setGivePermTo(arr)
            })
            .catch((error) => {
                if(error?.response?.status === 403){ 
                    setIsGetPerm(true);
                }
            })
    }
    useEffect(() => {
        getSingleRoleManagement();
        getSingleRolePermission();
    }, [params.id])

    
    
    const handleGivePermission =()=>{
        setGiveLoader(true);
        axios.patch(API_URLs.getGivePermission,{
            roleId: params.id,
            permissions: givePermTo
        },{
            headers:{
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then((res)=>{
            setGiveLoader(false);
            handleShowModalResponse(res?.data?.msg ? res?.data?.msg : "Permission Give Successfully!!");
            setGivePermTo([]);
            getSingleRolePermission();
        })
        .catch((error)=>{
            handleShowModalResponse(error?.response?.data?.message ? error.response?.data?.message : "Some Error occured");
            setGiveLoader(false);
        })
    }

    //reseting all permission
    const handleResetAllPermission =()=>{
        setResetLoader(true);
        axios.post(API_URLs.revokeAllPermission(params.id),{},{
            headers:{
                Accept: 'application/json',
                authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then((res)=>{  
            handleShowModalResponse(res?.data?.msg ? res?.data?.msg : "Permission Reset Successfully!!");
            setResetLoader(false);
            
            
            getSingleRolePermission();
        })
        .catch((error)=>{
            handleShowModalResponse(error?.response?.data?.message ? error.response?.data?.message : "Some Error occured");
            setResetLoader(false);
            
            
        })
    }

    
    
    const handleRevokePermission =(id)=>{
        axios.post(API_URLs.revokeSinglePermission,{
            roleId: params.id,
            permissionId: id
        },{
            headers:{
                Accept: 'application/json',
                authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then((res)=>{  
            
            
            getSingleRolePermission();
        })
        .catch((error)=>{
            
            
        })
    }

    const handleClick = (data)=>{
        if(!givePermTo?.find((e) => {return e === data._id})){
            setGivePermTo([...givePermTo, data._id]);
        }else{
            const arr = givePermTo.filter((e)=>{
                return e != data._id;
            })
            setGivePermTo(arr);
        }
    }
    return (
        <div className="container-fluid">
            <div className="m-2 mt-3" onClick={()=>navigate(-1)}>
                <FaLongArrowAltLeft/>
            </div>
            <div className="card p-2 m-3 mt-3" style={{ boxShadow: "0px 4px 10px rgba(0,0,0,0.25 "}} >
                <h5 className="p-2 pb-4 text-decoration-underline">Update Role</h5>
                <form className="d-flex flex-column gap-4 p-2" onSubmit={formik.handleSubmit} >
                    <div className="d-flex flex-column gap-3">
                        <label htmlFor="role">Role Name :</label>
                        <input
                            id="role"
                            name="role"
                            placeholder="Enter Name"
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            className="border border-muted px-3 py-1 rounded-3 "
                        />
                    </div>
                    <div className="d-flex justify-content-">
                        <Button disabled={ roleLoader} type="submit">{roleLoader ? <><SubmitLoader /> Updating... </>: 'Update'}</Button>
                    </div>
                </form>
            </div>
            <div className="card p-2 m-3 mt-3" style={{ boxShadow: "0px 4px 10px rgba(0,0,0,0.25 "}} >
                <h5 className="p-2 pb-4 text-decoration-underline">Permissions</h5>
                    {!isgetPerm ? <div className="p-3">
                        { givePermTo && Object.entries(getPerAllData).map((data, index)=>{
                                return <div className="mb-4">
                                <h6 className="text-decoration-underline">{index+1}. {data[0]?.toUpperCase()}</h6>
                                <div>
                                    {
                                        data[1]?.map((subdata)=>{
                                            return <div className="d-flex gap-2">
                                                <input
                                                    id="admin_read"
                                                    type="checkbox"
                                                    
                                                    
                                                    checked={givePermTo?.find((e) => {return e === subdata._id})  ? true: false}
                                                    
                                                    
                                                    onChange={()=>{
                                                        handleClick(subdata)
                                                    }}
                                                    
                                                    
                                                    
                                                    
                                                    
                                                    
                                                />
                                                <label htmlFor="admin_read">{subdata?.action}</label>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            })
                        }
                        <div className="d-flex flex-wrap gap-2">
                            <Button1 onClick={()=>handleGivePermission()}>{giveLoader ? <><SubmitLoader /> wait... </>: 'Give Permission'}</Button1>
                            <Button2 onClick={()=>handleResetAllPermission()}>{resetLoader ? <><SubmitLoader /> resetting... </>: 'Reset All Permission'}</Button2>
                        </div>
                    </div> : <p className="mb-0 d-flex justify-content-center align-items-center">You're not allowed to watch it.</p>}
            </div>
            {showRespModal && (<CustomModal
        showModal={showRespModal}
        handleClose={handleCloseModalResponse}
        navigateLink={navigateLink}
        modalData={modalDataResp}      />)}
        </div>
    );
};

export default SingleAdminRoleManagement;