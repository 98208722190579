// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-style {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  height: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/component/Navbar/NavbarMenu.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,YAAY;AACd","sourcesContent":[".navbar-style {\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n  height: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
