import React from 'react'

export default function ProfileLogoWhiteCopy() {
    return (
        <svg width='20' height='20' viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0C8.21989 0 5.14453 3.07536 5.14453 6.85547C5.14453 10.6356 8.21989 13.7109 12 13.7109C15.7801 13.7109 18.8555 10.6356 18.8555 6.85547C18.8555 3.07536 15.7801 0 12 0ZM20.5295 18.1896C18.6526 16.2839 16.1645 15.2344 13.5234 15.2344H10.4766C7.83553 15.2344 5.34735 16.2839 3.47048 18.1896C1.60279 20.086 0.574219 22.5892 0.574219 25.2383C0.574219 25.659 0.915266 26 1.33594 26H22.6641C23.0847 26 23.4258 25.659 23.4258 25.2383C23.4258 22.5892 22.3972 20.086 20.5295 18.1896Z" fill='black' />
        </svg>

    )
}
