import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './CustomModal.css'  
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RxCross1 } from "react-icons/rx";

const OkButton = styled.button`
border-radius: 46px;
padding: 13px 30px;
font-weight: 500;
border: none;
background: #1476FF;
font-size: 18px;
margin-bottom: 5px;
color: white;
transition: 0.5s;
:hover{
    transition: 0.5s;
    opacity: 0.7;
}
`
const Div = styled.div`
min-height: 3.3rem;
padding: 0 35px;
display: flex;
align-items: center;
font-size: 17px;
justify-content: center;
// font-weight: 500;
word-break: break-word;
`  
const CustomModal = ({ showModal, handleClose, navigateLink, modalData }) => {
    const navigate = useNavigate();
    const handleCloseFun = () =>{
        handleClose(false)
        if(navigateLink?.length > 0){
            navigate(navigateLink);
        }
    }
    const handleCancelFun =()=>{
        handleClose(false);
    }

    return (
        <Modal show={showModal} className='response-modal'>
            <Modal.Body style={{ color: 'black', backgroundColor: 'white', border: '1px solid grey', padding: '7px', borderRadius: '14px'}}>
                <div className='text-end mb-2 mt-2 me-2'   >
                   <button className='border-0 bg-transparent'onClick={()=>handleCancelFun()}> <RxCross1 color='#6F7182' size={22}/> </button>
                </div>
                <Div>
                    {modalData}
                </Div>
                <div className='mt-2 text-center px-4 py-2'>
                    <OkButton onClick={()=>handleCloseFun()}>OK</OkButton>
                </div>
            </Modal.Body>                
        </Modal>
    );
};
CustomModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    navigateLink: PropTypes.string,
    modalData: PropTypes.any.isRequired, 
};
export default CustomModal;