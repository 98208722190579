import React, { useEffect, useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import { HiMenuAlt2 } from "react-icons/hi";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BiLogOutCircle } from "react-icons/bi";
import SettingsIcon from '../../assets/SettingsIcon';
import ProfileLogoWhiteCopy from '../../assets/profile-logo-white copy';
import { useProSidebar } from "react-pro-sidebar";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import "./NavbarMenu.css";
import { useAuthContextHook } from "../../context/AuthContext";
import CustomModal from "../../component/Response/CustomModal";

import axios from "axios";
import { API_URLs } from "../../utils/ApiUrls";
const Span = styled.span`
    
`
const NavbarMenu = () => {
  const navigate = useNavigate();
  const { loadingOtpHandle, loadinghandle, user } = useAuthContextHook();

  const [showRespModal, setShowRespModal] = useState(false);
  const [modalDataResp, setModalDataResp] = useState(null);
  const [navigateLink, setNavigateLink] = useState('');
  const handleShowModalResponse = (data) => {
      setModalDataResp(data);
      setShowRespModal(true);
  };
  const handleCloseModalResponse = () => {
      setShowRespModal(false);
  };

  const { collapseSidebar, toggleSidebar } = useProSidebar();
  const [screenWidth, setScreenWidth] = useState();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    axios.post(API_URLs.logout,{
      refreshToken: localStorage.getItem('userTokenOut')
    },{
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
    .then((res)=>{
      localStorage.clear();
      handleShowModalResponse(res?.data?.msg ? res?.data?.msg : 'Logout Successfully!!');
      setNavigateLink('');
      navigate("/login");
      loadingOtpHandle(false);
      loadinghandle(false);
    })
    .catch((err)=>{
      setNavigateLink('');
      handleShowModalResponse(err?.response?.data?.message ? err?.response?.data?.message : 'Some Error Occured!!')
    })
  };

  return (
    <Navbar className="navbar-style d-flex justify-content-between bg-white" collapseOnSelect expand="lg">
      <HiMenuAlt2
        size="30"
        onClick={() => {
          if (screenWidth >= 1000) {
            collapseSidebar();
          } else {
            toggleSidebar();
          }
        }}
      />
      <NavDropdown
        className='pe-4'
        align={'start'}
        title={
          <>
            <Span>{user?.email}</Span>
            <span className='ps-2'>
              <ProfileLogoWhiteCopy color='black' />
            </span>
          </>
        } id="collasible-nav-dropdown-4">
        <NavDropdown.Item as={Link} to="/settings" className='p-2 d-flex align-items-center gap-2 justify-content-start'>
          <SettingsIcon color='black' />
          <span className='Link'>Settings</span>
        </NavDropdown.Item>
        <NavDropdown.Item onClick={handleLogout}
          className='p-2 d-flex align-items-center gap-2 justify-content-start'>
          <BiLogOutCircle color="red" />
          <span className='Link text-danger' >Logout</span>
        </NavDropdown.Item>
      </NavDropdown>
      {showRespModal && (<CustomModal
        showModal={showRespModal}
        handleClose={handleCloseModalResponse}
        navigateLink={navigateLink}
        modalData={modalDataResp}/>)}
    </Navbar>
  );
};

export default NavbarMenu;
