import React from "react";
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import ErrImg from '../../assets/error404.png'
import "./NotFound.css";


const Container = styled.div`
    min-height: 80vh;
    background: #fff;
`

const Card = styled.div`
    background: ${({ theme }) => theme.body === "#F7F9FC" ? 'white' : theme.body};
    border-radius: 26px;
    box-shadow: ${({ theme }) => theme.boxshadow};
    border: ${({ theme }) => theme.border};
`

const Heading = styled.h1`
    font-family: 'Inter';
    font-size: 80px;
    linght-height: 112px;
    font-weight: 700;
    text-align: center;
    color: #222222;
    @media screen and (max-width: 600px){
        font-size: 76px;

    }
`

const Text = styled.p`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0B0E2C;
    color: ${({ theme }) => theme.text === "#121620" ? "#0B0E2C" : theme.text};
    @media screen and (max-width: 600px){
        font-size: 18px;
        text-align: center;
        padding: 0 5px;

    }
`

const Button = styled.button`
    width: 200px;
    height: 40px;
    border: none;
    background: #3545EE;
    font-style: 'Inter';
    font-weight: 600;
    font-size: 25px
    outline: none;
    border-radius: 12px;
    text-decoration: none;
    // :hover{
    //     color: white;
    //     opacity: 0.9;
    // }
`



const NotFound = () => {


  const navigate = useNavigate();

  const handleNavigate = () => {
    if (localStorage.getItem('jwt')) {
      navigate('/dashboard')
    }
    else {
      navigate('/dashboard')
    }
  }

  return (
    // <section className="page_404">
    //   <div className="container">
    //     <div className="">
    //       <div className="">
    //         <div className="col-sm-offset-1  text-center">
    //           <div className="four_zero_four_bg">
    //             <h1 className="text-center ">404</h1>
    //           </div>
    //           <div className="contant_box_404">
    //             <h3 className="h2">Look like you're lost</h3>
    //             <p>the page you are looking for not avaible!</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <Container className='d-flex flex-column align-items-center justify-content-end'>
      <div className='d-flex flex-column align-items-center justify-content-end mb-5'>
        <img className='' src={ErrImg} alt="" />
        <Heading>404 Error</Heading>
        <Text>
          <span className='d-lg-block'>Ooops! It seems like we’re having some difficulties;</span>
          <span className='d-lg-block'>please don’t abandon ship, we’re sending for help.</span>
        </Text>
        <Button onClick={handleNavigate} className='text-white'>Go back home</Button>
      </div>
    </Container>
  );
};

export default NotFound;
