import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContextHook } from '../../context/AuthContext';

const PrivateRoute = () => {
  const { IsLogedIn } = useAuthContextHook();

  return IsLogedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;



